import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

var iconsMap = {
   BarChartIcon: BarChartIcon,
   CalendarTodayIcon: CalendarTodayIcon,
   ChatIcon: ChatIcon,
   CodeIcon: CodeIcon,
   DashboardIcon: DashboardIcon,
   ErrorIcon: ErrorIcon,
   FolderIcon: FolderIcon,
   DashboardTwoToneIcon: DashboardTwoToneIcon,
   GradeTwoTone: GradeTwoTone,
   ListAltIcon: ListAltIcon,
   LockOpenIcon: LockOpenIcon,
   MailIcon: MailIcon,
   PresentToAllIcon: PresentToAllIcon,
   PeopleIcon: PeopleIcon,
   PersonIcon: PersonIcon,
   ReceiptIcon: ReceiptIcon,
   SettingsIcon: SettingsIcon,
   ViewModuleIcon: ViewModuleIcon
};

setTimeout(() => { }, 3000);

var M = '';
var R = localStorage.getItem('role')
if (R === 'SA') {
   M = `[
      {
         "label":"Dashboards",
         "icon":"DashboardTwoToneIcon",
         "to":"/admin"
      },
      {
         "label":"Applicant",
         "icon":"PeopleIcon",
         "to":"/applicant"
      },
      {
         "label":"Contracts",
         "icon":"SettingsIcon",
         "to":"/contract"
      },
      {
         "label":"Data Pribadi",
         "icon":"PersonIcon",
         "to":"/candidate"
      },
      {
         "label":"Dokumen",
         "icon":"DashboardTwoToneIcon",
         "content":[
            {
               "label":"Upload",
               "description":"This is a dashboard page example built using this template.",
               "to":"/document/upload"
            },
            {
               "label":"Download",
               "description":"This is a dashboard page example built using this template.",
               "to":"/document/download"
            }
         ]
      }
   ]`
} else if (R === 'BS') {
   M = `
   [
      {
         "label": "Dashboards",
         "icon": "DashboardTwoToneIcon",
         "to": "/admin"
      },{
         "label": "Applicant",
      "icon": "PeopleIcon",
      "to": "/applicant"
      }
      
   ]
   `
} else if (R === 'HR') {
   M = `
   [
      {
         "label": "Dashboards",
         "icon": "DashboardTwoToneIcon",
         "to": "/admin"
      },{
         "label": "Contracts",
      "icon": "SettingsIcon",
      "to": "/contract"
      }
      
   ]
   `
} else {
   M = `
   [
      {
         "label":"Data Pribadi",
         "icon":"PersonIcon",
         "to":"/candidate"
      },{
         "label":"Dokumen",
         "icon":"DashboardTwoToneIcon",
         "content":[
            {
               "label":"Upload",
               "description":"This is a dashboard page example built using this template.",
               "to":"/document/upload"
            },{
               "label":"Download",
               "description":"This is a dashboard page example built using this template.",
               "to":"/document/download"
            }
         ]
      }
   ]
   `
}

export default [
   {
      label: 'Navigation menu',
      content: JSON.parse(M, (key, value) => {
         if (key === 'icon') {
            return iconsMap[value];
         } else {
            return value;
         }
      })
   }
];