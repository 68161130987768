import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';

import { connect } from 'react-redux';

import projectLogo from '../../assets/images/permata-white.png';
import projectIcon from '../../assets/images/permata-icon.png';

const HeaderLogo = props => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}>
        <Box
          className="header-logo-wrapper"
          title="Permata Indonesia">
          {sidebarToggle ? <img
            // className="app-header-logo-img"
            style={{ height: 40, width: "auto" }}
            alt="Permata Indonesia"
            src={projectIcon}
          /> :
            <img
              // className="app-header-logo-img"
              style={{ height: 40, width: "auto" }}
              alt="Permata Indonesia"
              src={projectLogo}
            />}



          {/* <Link to="/DashboardDefault" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn"> */}
          {/* </IconButton>
          </Link> */}
          {/* <Box className="header-logo-text">Carolina</Box> */}
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
