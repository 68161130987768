import React, {Fragment} from 'react';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {Drawer, Hidden, Paper} from '@material-ui/core';

import {connect} from 'react-redux';

import SidebarHeader from '../../layout-components/SidebarHeader';
import SidebarMenu from '../../layout-components/SidebarMenu';

import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import PersonOutline from '@material-ui/icons/PersonOutline';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import TransferWithinAStationOutlinedIcon from '@material-ui/icons/TransferWithinAStationOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

import {
    setSidebarFooter,
    setSidebarHover,
    setSidebarToggle,
    setSidebarToggleMobile,
    setSidebarUserbox
} from '../../reducers/ThemeOptions';

var iconsMap = {
    BarChartIcon: BarChartIcon,
    CalendarTodayIcon: CalendarTodayIcon,
    ChatIcon: ChatIcon,
    CodeIcon: CodeIcon,
    DashboardIcon: DashboardIcon,
    ErrorIcon: ErrorIcon,
    FolderIcon: FolderIcon,
    DashboardTwoToneIcon: DashboardTwoToneIcon,
    GradeTwoTone: GradeTwoTone,
    ListAltIcon: ListAltIcon,
    LockOpenIcon: LockOpenIcon,
    MailIcon: MailIcon,
    PresentToAllIcon: PresentToAllIcon,
    PeopleIcon: PeopleIcon,
    PersonIcon: PersonIcon,
    ReceiptIcon: ReceiptIcon,
    SettingsIcon: SettingsIcon,
    ViewModuleIcon: ViewModuleIcon,
    PersonOutline: PersonOutline,
    BusinessCenterIcon : BusinessCenterIcon,
    TransferWithinAStationOutlinedIcon: TransferWithinAStationOutlinedIcon,
    AssignmentOutlinedIcon,
    AssignmentIndOutlinedIcon,
    AssignmentTurnedInOutlinedIcon
};

// import navItems from './navItems';

const Sidebar = props => {
    const {
        setSidebarToggleMobile,
        sidebarToggleMobile,
        sidebarFixed,

        sidebarHover,
        setSidebarHover,
        sidebarToggle,
        sidebarUserbox,
        sidebarShadow,
        sidebarFooter
    } = props;

    const toggleHoverOn = () => setSidebarHover(true);
    const toggleHoverOff = () => setSidebarHover(false);

    const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

    var M = '';
    const R = localStorage.getItem('role')

    if (R === 'SA') {
        M = `[
      {
         "label":"Dashboards",
         "icon":"DashboardTwoToneIcon",
         "to":"/admin"
      },
      {
         "label":"Applicant",
         "icon":"PeopleIcon",
         "to":"/applicant"
      },
      {
        "label": "Employee",
        "icon": "TransferWithinAStationOutlinedIcon",
        "content": [
          {
            "label": "Contract",
            "icon": "AssignmentOutlinedIcon",
            "to": "/contract-employee"
          },
          {
            "label": "Pending Contract",
            "icon": "AssignmentIndOutlinedIcon",
            "to": "/contract-pending"
          },
          {
            "label": "Completed Contract",
            "icon": "AssignmentTurnedInOutlinedIcon",
            "to": "/contract-completed"
          }
        ]
      },
      {
        "label": "User",
         "icon": "PersonOutline",
          "to": "/user"
      },
      {
         "label":"Contracts",
         "icon":"SettingsIcon",
         "to":"/contract"
      },
      {
         "label": "BPJS",
         "icon": "BusinessCenterIcon",
          "to": "/bpjs"
      },
      {
         "label": "Tax",
         "icon": "BusinessCenterIcon",
          "to": "/tax"
      }
   ]`
    } else if (R === 'BS') {
        M = `
       [
          {
            "label": "Dashboards",
            "icon": "DashboardTwoToneIcon",
            "to": "/admin"
          },
          {
            "label": "Applicant",
            "icon": "PeopleIcon",
            "to": "/applicant"
          },
          {
            "label": "Employee",
            "icon": "TransferWithinAStationOutlinedIcon",
            "content": [
              {
                "label": "Contract",
                "description":"Menu untuk generate contract Rejoin/Extend Contract",
                "icon": "AssignmentOutlinedIcon",
                "to": "/contract-employee"
              },
              {
                "label": "Pending Contract",
                "description":"Menu untuk menyelesaikan proses Rejoin/Extend Contract hingga Inject to IOS",
                "icon": "AssignmentIndOutlinedIcon",
                "to": "/contract-pending"
              },
              {
                "label": "Completed Contract",
                "description":"List TKO yang berhasil Rejoin/Extend",
                "icon": "AssignmentTurnedInOutlinedIcon",
                "to": "/contract-completed"
              }
            ]
          }
        ]
   `
    } else if (R === 'RO' || R === 'MA') {
        M = `
   [
      {
         "label": "Dashboards",
         "icon": "DashboardTwoToneIcon",
         "to": "/admin"
      },{
         "label": "Applicant",
         "icon": "PeopleIcon",
          "to": "/applicant"
      }
   ]
   `
    } else if (R === 'AC' || R === 'BM' || R === 'MIS') {
        M = `
   [
      {
         "label": "Dashboards",
         "icon": "DashboardTwoToneIcon",
         "to": "/admin"
      },
      {
         "label": "Applicant",
         "icon": "PeopleIcon",
          "to": "/applicant"
      },
      {
        "label": "Employee",
        "icon" : "TransferWithinAStationOutlinedIcon",
        "content": [
            {
             "label":"Pending Contract",
             "description":"Menu untuk menyelesaikan proses Rejoin/Extend hingga Inject to IOS",
             "icon":"AssignmentIndOutlinedIcon",
             "to":"/contract-pending"
            },{
             "label":"Completed Contract",
             "description":"List TKO yang berhasil Rejoin/Extend",
             "icon":"AssignmentTurnedInOutlinedIcon",
             "to":"/contract-completed"
            }
        ] 
      }
   ]
   `
    } else if (R === 'HR') {
        M = `
   [
      {
         "label": "Dashboards",
         "icon": "DashboardTwoToneIcon",
         "to": "/admin"
      },{
         "label": "Applicant",
         "icon": "PeopleIcon",
          "to": "/applicant"
      },{
         "label": "Contracts",
         "icon": "SettingsIcon",
         "to": "/contract"
      }
      
   ]
   `
    } else if (R === 'BPJS') {
        M = `
   [
      {
         "label":"Dashboards",
         "icon":"DashboardTwoToneIcon",
         "to":"/admin"
      },{
         "label": "BPJS",
         "icon": "BusinessCenterIcon",
          "to": "/bpjs"
      }
   ]
   `
    } else {
        M = `
   [
      {
         "label":"",
         "icon":"",
         "to":"/candidate"
      }
   ]
   `
    }

    const navItems = [
        {
            label: 'Navigation menu',
            content: JSON.parse(M, (key, value) => {
                if (key === 'icon') {
                    return iconsMap[value];
                } else {
                    return value;
                }
            })
        }
    ]

    const sidebarMenuContent = (
        <div
            className={clsx({
                'app-sidebar-nav-close': sidebarToggle && !sidebarHover
            })}>
            {navItems.map(list => (
                <SidebarMenu
                    component="div"
                    key={list.label}
                    pages={list.content}
                    title={list.label}
                />
            ))}
        </div>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    open={sidebarToggleMobile}
                    onClose={closeDrawer}
                    variant="temporary"
                    elevation={4}
                    className="app-sidebar-wrapper-lg">
                    <SidebarHeader/>
                    <PerfectScrollbar>
                        {/* {sidebarUserbox && <SidebarUserbox />} */}
                        {sidebarMenuContent}
                        {/* {sidebarFooter && <SidebarFooter />} */}
                    </PerfectScrollbar>
                </Drawer>
            </Hidden>

            <Hidden mdDown>
                <Paper
                    onMouseEnter={toggleHoverOn}
                    onMouseLeave={toggleHoverOff}
                    className={clsx('app-sidebar-wrapper', {
                        'app-sidebar-wrapper-close': sidebarToggle,
                        'app-sidebar-wrapper-open': sidebarHover,
                        'app-sidebar-wrapper-fixed': sidebarFixed
                    })}
                    square
                    open={sidebarToggle}
                    elevation={sidebarShadow ? 11 : 3}>
                    <SidebarHeader/>
                    <div
                        className={clsx({
                            'app-sidebar-menu': sidebarFixed,
                            'app-sidebar-collapsed': sidebarToggle && !sidebarHover
                        })}>
                        <PerfectScrollbar options={{wheelPropagation: false}}>
                            {/* {sidebarUserbox && <SidebarUserbox />} */}
                            {sidebarMenuContent}
                            {/* {sidebarFooter && <SidebarFooter />} */}
                        </PerfectScrollbar>
                    </div>
                </Paper>
            </Hidden>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    sidebarFixed: state.ThemeOptions.sidebarFixed,
    headerFixed: state.ThemeOptions.headerFixed,
    sidebarToggle: state.ThemeOptions.sidebarToggle,
    sidebarHover: state.ThemeOptions.sidebarHover,
    sidebarShadow: state.ThemeOptions.sidebarShadow,
    sidebarFooter: state.ThemeOptions.sidebarFooter,
    sidebarUserbox: state.ThemeOptions.sidebarUserbox,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
    setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
    setSidebarHover: enable => dispatch(setSidebarHover(enable)),
    setSidebarFooter: enable => dispatch(setSidebarFooter(enable)),
    setSidebarUserbox: enable => dispatch(setSidebarUserbox(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
